import React from 'react'
import PropTypes from 'prop-types'
import styled, { css, up } from '@xstyled/styled-components'

const InnerWrapper = styled.div`
  padding: 60rpx 0;
  width: 100vw;

  ${up(
    'md',
    css`
      padding: ${p => p.newsEvents ? '150px 0 100px' : '150px 0'};
    `
  )}
`

const SectionInnerWrapper = ({ children, className }) => {
  return (
    <InnerWrapper newsEvents={InnerWrapper} className={className}>
      {children}
    </InnerWrapper>
  )
}

export default SectionInnerWrapper

SectionInnerWrapper.defaultProps = {
  className: ''
}

SectionInnerWrapper.propTypes = {
  className: PropTypes.string
}