import React from 'react'
import PropTypes from 'prop-types'
import styled from '@xstyled/styled-components'
import VisibilitySensor from 'react-visibility-sensor'
import parse from 'html-react-parser'
import anime from 'animejs'

import Container from '../layout/container'
import SectionInnerWrapper from  './section-inner-wrapper'

const StyledSection = styled.section`
  display: flex;
  align-items: center;

   h3 {
    color: #FFF;
    text-align: center;
   }
`

const Title = styled.div`
  color: var(--ssy-white);
  text-align: center;
`

const SectionTitle = ({ className, id, title }) => {
  let animeFinished = false

  const animateSection = (isVisible) => {
    if (isVisible && !animeFinished) {
      const animateBkg = anime.timeline({
        duration: 1000,
        easing: 'easeInOutQuad',
      })

      animateBkg
        .add({
          targets: `#${id}`,
          backgroundColor: ['#FFF', '#D9381C'],
          complete: function () {
            animeFinished = true
          }
        })
      
      animateBkg.play()
    }
  }
  return (
    <StyledSection id={id} className={className}>
      <VisibilitySensor onChange={isVisible => animateSection(isVisible)}>
        <SectionInnerWrapper>
          <Container>
            <Title>{parse(title)}</Title>
          </Container>
        </SectionInnerWrapper>
      </VisibilitySensor>
    </StyledSection>
  )
}

export default SectionTitle

SectionTitle.defaultTypes = {
  className: 'section',
  title: '',
}

SectionTitle.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
}